import logo from './yubi_logo.svg'
import './App.css'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'

function App() {
  return (
    <Router>
      <div className='App bg-gradient-to-tr from-secondaryBrand to-primaryBrand'>
        <header className='App-header'>
          <Routes>
            <Route
              path='/'
              element={
                <>
                  <img src={logo} className='App-logo' alt='logo' />
                  <h2 className='text-4xl font-semibold mb-8 mt-8'>
                    Yubi Landing Page
                  </h2>

                  <div className='flex w-3/4 justify-center flex-col space-y-4 md:space-y-0 md:flex-row md:space-x-4'>
                    <a
                      className='rounded-full bg-white text-primaryBrand px-6 py-2'
                      href='https://yubipro.com/dachegok'
                    >
                      Go to Dachegok S-ERP
                    </a>
                    <a
                      className='rounded-full  bg-primaryBrand  px-6 py-2'
                      href='http://yubiteck.com/'
                    >
                      Go To Yubi Homepage
                    </a>
                  </div>
                </>
              }
            />
          </Routes>
        </header>
      </div>
    </Router>
  )
}

export default App
